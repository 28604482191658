// extracted by mini-css-extract-plugin
export var card = "CompanyInfoCard-module--card--fcbf4";
export var content = "CompanyInfoCard-module--content--96e57";
export var detail = "CompanyInfoCard-module--detail--333ba";
export var detailsArea = "CompanyInfoCard-module--detailsArea--01123";
export var initiativeList = "CompanyInfoCard-module--initiativeList--c5f95";
export var logo = "CompanyInfoCard-module--logo--3e22d";
export var logoArea = "CompanyInfoCard-module--logoArea--dc06c";
export var opacityEnter = "CompanyInfoCard-module--opacityEnter--2d248";
export var opacityExit = "CompanyInfoCard-module--opacityExit--1a6db";
export var rollDown = "CompanyInfoCard-module--rollDown--a610f";
export var rollUp = "CompanyInfoCard-module--rollUp--e690a";
export var slideInDown = "CompanyInfoCard-module--slideInDown--a8d1e";
export var slideOutUp = "CompanyInfoCard-module--slideOutUp--a69ea";
export var splashEnter = "CompanyInfoCard-module--splashEnter--0d8d8";
export var splashExit = "CompanyInfoCard-module--splashExit--7c026";
export var textBig = "CompanyInfoCard-module--textBig--ddced";
export var textSmall = "CompanyInfoCard-module--textSmall--ec5da";
// extracted by mini-css-extract-plugin
export var arrow = "Customers-module--arrow--40a43";
export var backLinkWrapper = "Customers-module--backLinkWrapper--d045d";
export var container = "Customers-module--container--8450e";
export var contentWrapper = "Customers-module--contentWrapper--a5274";
export var headingH2 = "Customers-module--headingH2--e8f96";
export var headingH3 = "Customers-module--headingH3--25317";
export var headingH4 = "Customers-module--headingH4--f9204";
export var headingH5 = "Customers-module--headingH5--2f6e6";
export var headingH6 = "Customers-module--headingH6--586a6";
export var opacityEnter = "Customers-module--opacityEnter--a1686";
export var opacityExit = "Customers-module--opacityExit--b6aeb";
export var relatedPostsWrapper = "Customers-module--relatedPostsWrapper--1f0e8";
export var rollDown = "Customers-module--rollDown--04494";
export var rollUp = "Customers-module--rollUp--fae3c";
export var slideInDown = "Customers-module--slideInDown--cda40";
export var slideOutUp = "Customers-module--slideOutUp--a5471";
export var splashEnter = "Customers-module--splashEnter--6a9cd";
export var splashExit = "Customers-module--splashExit--43a49";
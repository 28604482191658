import React from 'react';
import {
  CustomersDetailsTableViewType,
  Table,
  TableCell,
  TransformedTableCells,
} from './CustomersDetailsTableView.types';
import * as styles from './CustomersDetailsTableView.module.scss';

function groupRelatedCol({ tbody, thead }: Omit<Table, 'fieldtype'>) {
  return thead.reduce((prevColumnValue, currentColumnValue, columnIndex) => {
    const transformedTableBody = tbody.reduce((prevBodyValue, currentBodyValue) => {
      return [...prevBodyValue, currentBodyValue.body[columnIndex]];
    }, [] as TableCell[]);

    return [
      ...prevColumnValue,
      { _uid: currentColumnValue._uid, value: transformedTableBody[0]?.value, title: transformedTableBody[1]?.value },
    ];
  }, [] as TransformedTableCells[]);
}

export default function CustomersDetailsTableView({ details }: CustomersDetailsTableViewType) {
  if (!details) return null;

  return (
    <section>
      {details && (
        <table>
          <tr className={styles.row}>
            {groupRelatedCol({ ...details })?.map(tableCell => {
              return (
                <td key={tableCell._uid} className={`${styles.tableData}`}>
                  <h3 className={styles.heading}>{tableCell.value}</h3>
                  <p className={styles.paragraph}>{tableCell.title}</p>
                </td>
              );
            })}
          </tr>
        </table>
      )}
    </section>
  );
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ConfigContext } from '@/base/context/Config/provider';
import withLayout from '@/base/containers/withLayout';
import Link from '@/components/elements/Link';
import TestimonialCard from '@/components/modules/TestimonialCard';
import HeroWithSidebarVariant from '@/components/sections/HeroWithSidebarVariant/HeroWithSidebarVariant';
import CompanyInfoCard from '@/components/modules/CompanyInfoCard';
import RelatedPosts from '@/components/modules/RelatedPosts';
import SectionRichText from '@/components/sections/SectionRichText';
import { BREAKPOINTS } from '@/base/consts/breakpoints';
import type { CustomersTemplateProps, CustomersTypes } from './Customers.types';
import * as styles from './Customers.module.scss';

export function Customers({ content, full_slug }: StoryblokPage<CustomersTypes>) {
  const {
    state: { config },
  } = useContext(ConfigContext);
  const isTablet = useMediaQuery({ query: BREAKPOINTS.LG });
  const {
    title,
    body,
    quote,
    title_company,
    industry,
    business_initiative,
    headquarters,
    revenue,
    employees,
    key_applications,
  } = content;

  const testimonialCardRef = useRef<HTMLDivElement>(null);
  const [testimonialCardHeight, setTestimonialCardHeight] = useState(0);
  const testimonialCardPadding = 105;
  useEffect(() => {
    if (testimonialCardRef.current) {
      setTestimonialCardHeight(testimonialCardRef.current.offsetHeight + testimonialCardPadding);
    }
  }, [isTablet]);

  return (
    <section>
      <div className={styles.backLinkWrapper}>
        <Link link={config?.customers_back_link} display_arrow arrow_direction="left">
          {config?.customers_back_link_label}
        </Link>
      </div>
      <HeroWithSidebarVariant
        {...content}
        allowVideo
        display_image
        case_study_top_text={config?.customers_hero_upper_text}
        title={title}
        excerpt={undefined}
        sidebarDeps={[industry, business_initiative, headquarters, revenue, employees, key_applications]}
      >
        <CompanyInfoCard {...content} fieldsHeading={config} />
        {quote && (
          <div ref={testimonialCardRef}>
            <TestimonialCard quote={quote} name={content.name} title={title_company} />
          </div>
        )}
      </HeroWithSidebarVariant>
      {body?.type && (
        <div className={styles.container} style={{ minHeight: isTablet ? 'auto' : `${testimonialCardHeight}px` }}>
          <div className={styles.contentWrapper}>
            <SectionRichText body={body} className={styles} />
          </div>
        </div>
      )}
      <div className={styles.relatedPostsWrapper}>
        <RelatedPosts
          slug={full_slug}
          title={config?.customers_related_posts_title}
          label={config?.customers_card_link_label || config?.card_link_label}
        />
      </div>
    </section>
  );
}

export default function CustomersTemplate({ pageContext, location }: CustomersTemplateProps) {
  return withLayout(Customers, { ...pageContext, location });
}

import React, { Children, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '@/base/consts/breakpoints';
import Image from '@/components/elements/Image';
import Video from '@/components/elements/Video';
import CustomersDetailsTableView from '@/components/modules/CustomersDetailsTableView';
import { webinarDateFormat } from '@/base/helpers/dates';
import { removeHTMLTags, removeHypehenat, removePrefix } from '@/base/helpers/strings';
import { Table } from '@/components/modules/CustomersDetailsTableView/CustomersDetailsTableView.types';
import { swapToStoryblokV2Domain } from '@/components/elements/Image/helpers/swapDomain';
import type { HeroWithSidebarVariantTypes } from './HeroWithSidebarVariant.types';
import * as styles from './HeroWithSidebarVariant.module.scss';

export default function HeroWithSidebarVariant({
  theme = 'light',
  date,
  time,
  image,
  allowVideo,
  background,
  case_study_top_text,
  display_image,
  subhead,
  excerpt,
  event_type,
  title,
  type,
  stats,
  video_url,
  children,
  sidebarDeps,
  sidebarRef,
}: React.PropsWithChildren<HeroWithSidebarVariantTypes>) {
  const isTablet = useMediaQuery({ query: BREAKPOINTS.LG });
  const isAssetVisible = String(display_image);
  const shouldShowAsset = String(isAssetVisible === 'true');

  const checkStatsExist = (stats: Table) => {
    const hasValuesArray = stats.tbody.map(el => el.body.some(text => text.value));
    return !!hasValuesArray.filter(value => !!value).length;
  };

  const renderSidebar = useCallback(() => {
    return (
      <aside className={styles.asideWrapper} ref={sidebarRef}>
        <div className={styles.aside}>
          {Children.map(children, child => (
            <div className="aside-item-shadow">{child}</div>
          ))}
        </div>
      </aside>
    );
  }, [isTablet, children, ...(sidebarDeps || [])]);

  return (
    <div className={styles.container}>
      <section
        className={styles.wrapper}
        style={background ? { backgroundImage: `url(${swapToStoryblokV2Domain(background + '/m/')})` } : {}}
        data-theme={String(theme)}
        data-asset-visible={shouldShowAsset}
      >
        <div className={styles.heroContent}>
          <div className={styles.contentWrapper}>
            <p className={styles.category}>
              {removePrefix(type, '/')?.toLowerCase() === 'e-book'
                ? 'E-book'
                : removePrefix(removeHypehenat(event_type || type || case_study_top_text), '/')}
            </p>
            {date && (
              <p className={styles.date}>
                {webinarDateFormat(date)} {time ? `- ${time}` : ''}
              </p>
            )}
            <h1 className={`mb-5 ${styles.title}`}>{title}</h1>
            {(subhead || excerpt) && <p className={styles.excerpt}>{removeHTMLTags(subhead || excerpt)}</p>}
            {stats && checkStatsExist(stats) && (
              <div className={styles.tableWrapper}>
                <CustomersDetailsTableView details={stats} />
              </div>
            )}
          </div>
          {!isTablet && (
            <div className={styles.desktopViewport}>
              <div className={styles.desktopWrapper}>
                <div className={styles.childrenWrapper}>{renderSidebar()}</div>
              </div>
            </div>
          )}
        </div>
      </section>
      <div className={styles.overlapSpace} data-asset-visible={shouldShowAsset} />
      {(video_url || (image && display_image)) && (
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              {allowVideo && video_url && video_url?.trim().length ? (
                <Video video_url={video_url} thumbnailUrl={image} />
              ) : (
                display_image && image && <Image data={image} fluid className={styles.image} />
              )}
            </div>
          </div>
        </div>
      )}
      {isTablet && (
        <div className={styles.mobileWrapper} data-asset-visible={shouldShowAsset}>
          {renderSidebar()}
        </div>
      )}
    </div>
  );
}

import React from 'react';
import * as styles from './style.module.scss';
import { TestimonialCardTypes } from './TestimonialCard.types';

export default function TestimonialCard({ quote, name, title }: TestimonialCardTypes) {
  return (
    <div className={styles.card}>
      <span className={styles.quoteMark}>&ldquo; </span>
      <q className={styles.text}>{quote}</q>
      <div className={styles.personArea}>
        <p>{name}</p>
        <p>{title}</p>
      </div>
    </div>
  );
}

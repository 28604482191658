import React from 'react';
import Image from '@/components/elements/Image';
import { CompanyInfoCardTypes } from './CompanyInfoCard.types';
import * as styles from './CompanyInfoCard.module.scss';

export default function CompanyInfoCard({
  logo,
  industry,
  business_initiative,
  headquarters,
  revenue,
  employees,
  key_applications,
  fieldsHeading,
}: CompanyInfoCardTypes) {
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <div className={styles.logoArea}>
          <Image data={logo} fluid className={styles.logo} alt={logo?.alt_tag} />
        </div>
        <div className={styles.detailsArea}>
          {industry && (
            <div className={styles.detail}>
              <h3 className={styles.textSmall}>{fieldsHeading?.customers_heading_industry}</h3>
              <p className={styles.textBig}>{industry}</p>
            </div>
          )}
          {business_initiative.length > 0 && (
            <div className={styles.detail}>
              <h3 className={styles.textSmall}>{fieldsHeading?.customers_heading_business_initiative}</h3>
              {business_initiative?.length && (
                <ul className={styles.initiativeList}>
                  {business_initiative?.map(initiative => (
                    <li className={styles.textBig} key={initiative}>
                      {initiative}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          {headquarters && (
            <div className={styles.detail}>
              <h3 className={styles.textSmall}>{fieldsHeading?.customers_heading_headquarters}</h3>
              <p className={styles.textBig}>{headquarters}</p>
            </div>
          )}
          {revenue && (
            <div className={styles.detail}>
              <h3 className={styles.textSmall}>{fieldsHeading?.customers_heading_revenue}</h3>
              <p className={styles.textBig}>{revenue}</p>
            </div>
          )}
          {employees && (
            <div className={styles.detail}>
              <h3 className={styles.textSmall}>{fieldsHeading?.customers_heading_employees}</h3>
              <p className={`${styles.textBig}`}>{employees}</p>
            </div>
          )}
          {key_applications && (
            <div className={styles.detail}>
              <h3 className={styles.textSmall}>{fieldsHeading?.customers_heading_key_application}</h3>
              <p className={styles.textBig}>{key_applications}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// extracted by mini-css-extract-plugin
export var heading = "CustomersDetailsTableView-module--heading--830f2";
export var opacityEnter = "CustomersDetailsTableView-module--opacityEnter--9a1b0";
export var opacityExit = "CustomersDetailsTableView-module--opacityExit--196cd";
export var paragraph = "CustomersDetailsTableView-module--paragraph--21bf8";
export var rollDown = "CustomersDetailsTableView-module--rollDown--6e74d";
export var rollUp = "CustomersDetailsTableView-module--rollUp--fc49c";
export var row = "CustomersDetailsTableView-module--row--b45f0";
export var slideInDown = "CustomersDetailsTableView-module--slideInDown--26da8";
export var slideOutUp = "CustomersDetailsTableView-module--slideOutUp--adfa1";
export var splashEnter = "CustomersDetailsTableView-module--splashEnter--5b4ed";
export var splashExit = "CustomersDetailsTableView-module--splashExit--6e7bd";
export var tableData = "CustomersDetailsTableView-module--tableData--9c80d";
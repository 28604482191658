// extracted by mini-css-extract-plugin
export var card = "style-module--card--a637e";
export var opacityEnter = "style-module--opacityEnter--92fe0";
export var opacityExit = "style-module--opacityExit--80573";
export var personArea = "style-module--personArea--1d65c";
export var quote = "style-module--quote--5fa3c";
export var quoteMark = "style-module--quoteMark--ce07c";
export var rollDown = "style-module--rollDown--0ed87";
export var rollUp = "style-module--rollUp--5a757";
export var slideInDown = "style-module--slideInDown--43539";
export var slideOutUp = "style-module--slideOutUp--19596";
export var splashEnter = "style-module--splashEnter--c2e65";
export var splashExit = "style-module--splashExit--a0b9a";
export var text = "style-module--text--684be";
// extracted by mini-css-extract-plugin
export var aside = "HeroWithSidebarVariant-module--aside--67f91";
export var asideWrapper = "HeroWithSidebarVariant-module--asideWrapper--69885";
export var category = "HeroWithSidebarVariant-module--category--05807";
export var childrenWrapper = "HeroWithSidebarVariant-module--childrenWrapper--00612";
export var container = "HeroWithSidebarVariant-module--container--d6268";
export var contentWrapper = "HeroWithSidebarVariant-module--contentWrapper--c311f";
export var date = "HeroWithSidebarVariant-module--date--22b2f";
export var desktopViewport = "HeroWithSidebarVariant-module--desktopViewport--83502";
export var desktopWrapper = "HeroWithSidebarVariant-module--desktopWrapper--d544f";
export var excerpt = "HeroWithSidebarVariant-module--excerpt--4e46b";
export var heroContent = "HeroWithSidebarVariant-module--heroContent--f650e";
export var image = "HeroWithSidebarVariant-module--image--9b94a";
export var mobileWrapper = "HeroWithSidebarVariant-module--mobileWrapper--1a965";
export var opacityEnter = "HeroWithSidebarVariant-module--opacityEnter--56398";
export var opacityExit = "HeroWithSidebarVariant-module--opacityExit--a24ab";
export var overlapSpace = "HeroWithSidebarVariant-module--overlapSpace--35ea2";
export var rollDown = "HeroWithSidebarVariant-module--rollDown--c5a98";
export var rollUp = "HeroWithSidebarVariant-module--rollUp--cb4fc";
export var slideInDown = "HeroWithSidebarVariant-module--slideInDown--e6999";
export var slideOutUp = "HeroWithSidebarVariant-module--slideOutUp--a7e80";
export var splashEnter = "HeroWithSidebarVariant-module--splashEnter--7cdaf";
export var splashExit = "HeroWithSidebarVariant-module--splashExit--a39f8";
export var tableWrapper = "HeroWithSidebarVariant-module--tableWrapper--864fb";
export var title = "HeroWithSidebarVariant-module--title--0dc4b";
export var wrapper = "HeroWithSidebarVariant-module--wrapper--491ce";